<template>
  <div class="mt-20 w450 excluded-area-container">
    <div class="flex justify-space-between align-center">
      <h3 class="txt-14 flex align-center txt-uppercase txt-bold txt-font-din-medium">
        {{ $t('components.labels.excludedArea') }}
        <a-tooltip
          :title="$t('components.toolTips.excludedArea')"
          class="ml-8">
          <a-icon type="info-circle" style="color: #c6c7c6; font-size: 20px"/>
        </a-tooltip>
      </h3>
      <a-button type="link" class="txt-orange pr-0" @click="() => active = true">
        <a-icon type="environment" class="mr-4"/>
        {{ $t('components.description.identifyAreasOnMap') }}
      </a-button>
    </div>
    <a-input
      v-model.trim="excludedText"
      data-cy="excluded-area-input"
      :placeholder="$t('components.description.enterExcludedArea')"
      class="mb-10"
      :disabled="isSupervisor"
      @keyup.enter="onEnter"/>
    <template v-for="area in excluded">
      <a-tag
        :key="area"
        :closable="!isSupervisor"
        class="mt-10"
        :disabled="isSupervisor"
        @close="removeExcluded(area)">
        <span class="txt-capitalize">{{ parseArea(area) }}</span>
      </a-tag>
    </template>
    <div>
      <ExcludeAreaModal
        v-if="active"
        :on-exit="onExit"
        :initial-lat="(metadata && metadata.latitude)"
        :initial-lng="(metadata && metadata.longitude)"
        @complete="onComplete"/>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import uniq from 'lodash/uniq';

export default {
  name: 'ExcludedGeographicArea',
  components: {
    ExcludeAreaModal: () => import('./exclude-area-modal')
  },
  data() {
    return {
      excludedText: '',
      active: false
    };
  },
  computed: {
    ...mapState({
      excluded: state => state.survey?.metadata?.excludedAreas || [],
      metadata: state => state.survey?.metadata,
      isSupervisor: state => state.survey.currentUserRoleSystem === 'srvy-sup'
    })
  },
  methods: {
    ...mapActions(['setMetadata']),
    parseArea(area) {
      const splitArea = area.replace(',', '').split(' ');
      return this.$t('components.description.radiusAround', {
        radius: splitArea[0],
        lat: splitArea[splitArea.length - 2],
        lng: splitArea[splitArea.length - 1]
      });
    },
    onEnter() {
      if (this.isSupervisor) {
        return;
      }
      if (this.excludedText.length > 0) {
        const excluded = uniq([...this.excluded, this.excludedText]
          .map(area => area.toLowerCase()));
        this.excludedText = '';
        this.save(excluded);
      }
    },
    removeExcluded(area) {
      if (this.isSupervisor) {
        return;
      }
      const excluded = this.excluded.filter(exclude => exclude !== area);
      this.save(excluded);
    },
    onComplete(excludedArea) {
      if (this.isSupervisor) {
        return;
      }
      const excluded = uniq([...this.excluded, excludedArea].map(area => area.toLowerCase()));
      this.onExit();
      this.save(excluded);
    },
    onExit() {
      this.active = false;
    },
    save(excluded) {
      if (this.isSupervisor) {
        return;
      }
      this.setMetadata({
        ...this.metadata,
        excludedAreas: excluded
      });
    }
  }
};
</script>

<style lang="scss" scoped>
</style>
